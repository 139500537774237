body {
    font-family: Arial, sans-serif;
    line-height: 1.6;
    margin: 0;
    padding: 0;
    background-color: #ffffff;
    color: #333;
}

.digital-transformation {
    max-width: 1200px;
    margin: 40px auto;
    padding: 0 20px;
    height: 100%;
    width: 100%;
}

.digital-transformation-image-container {
    display: flex;
margin: 0;
    height: 100%;
    width: 100%;
    border-radius: 8px 8px 0 0;

}

.digital-transformation-image-container img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 8px 8px 0 0;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);

    margin: 0;

}

.digital-transformation-content-container {
    background: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
    border-radius: 0 0 8px 8px;
    padding: 5%;
}

.digital-transformation-title {
    text-align: left;
    color: #4e699b;
}

.digital-transformation-intro {
    text-align: left;
    font-size: 1.2em;
    margin-bottom: 20px;
}

.digital-transformation-description {
    text-align: left;
    font-size: 1.1em;
    margin-bottom: 20px;
}

.digital-transformation-process {
    margin-top: 20px;
}

.digital-transformation-subtitle {
    color: #4e699b;
}

.digital-transformation-step {
    margin-bottom: 20px;
}

.digital-transformation-step h3 {
    color: #4e699b;
    font-size: 1.1em;
    margin-bottom: 10px;
}

.digital-transformation-step ul {
    list-style: disc;
    margin-left: 20px;
}

.digital-transformation-step ul li {
    margin-bottom: 5px;
}

.digital-transformation-why-choose-us {
    margin-top: 40px;
}

.digital-transformation-why-choose-us h2 {
    color: #4e699b;
}

.digital-transformation-why-choose-us ul {
    list-style: none;
    padding: 0;
}

.digital-transformation-why-choose-us ul li {
    margin-bottom: 10px;
    font-size: 1.1em;
}

.digital-transformation-cta {
    text-align: center;
    margin-top: 40px;
}

.digital-transformation-cta h2 {
    color: #4e699b;
    margin-bottom: 10px;
}

.digital-transformation-cta p {
    font-size: 1.1em;
    margin-bottom: 20px;
}

.digital-transformation-btn-learn-more {
    display: inline-block;
    padding: 10px 20px;
    background-color: #4e699b;
    color: #ffffff;


    text-decoration: none;
    border-radius: 5px;
    transition: background-color 0.3s;
}

.digital-transformation-btn-learn-more:hover {
    background-color: #003399;
}
