.problem-and-solution .section {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 20px;
  margin-bottom: 20px;
}

.problem-and-solution .shaded {
  background-color: #fcfaff; /* Light grey background for highlighting */
}

.problem-and-solution .problem.whitebg {
  background-color: white;
}

.problem-and-solution .problem-image,
.problem-and-solution .solution-image {
  flex: 1 1 50%;
  padding: 10px;
}

.problem-and-solution .problem-text,
.problem-and-solution .solution-text {
  flex: 1 1 50%;
  padding: 10px;
}

.problem-and-solution img {
  width: 800px;
  height: auto;
  border-radius: 3%;

}

.problem-and-solution h2 {
  font-size: 1.5em;
  margin-bottom: 10px;
}

.problem-and-solution p {
  font-size: 1em;
  line-height: 1.5;
  margin-bottom: 10px;
}

@media (max-width: 768px) {
  .problem-and-solution .section {
    flex-direction: column;
  }

  .problem-and-solution .problem-image,
  .problem-and-solution .solution-image,
  .problem-and-solution .problem-text, 
  .problem-and-solution .solution-text {
    flex: 1 1 100%;
  }

  /* Ensure text comes before the image on mobile */
  .problem-and-solution .problem-text,
  .problem-and-solution .solution-text {
    order: 1;
  }

  .problem-and-solution .problem-image,
  .problem-and-solution .solution-image {
    order: 2;
  }
}
