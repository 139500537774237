.who-am-i {
  padding: 20px;
  background-color: #ffffff; /* Changed to white for a clean look */
  border-radius: 10px;
  margin-bottom: 20px;
  text-align: center;
}

.who-am-i-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1200px; /* Set a max-width for the content */
  margin: 0 auto; /* Center the content */
}

.who-am-i-image {
  margin-bottom: 20px;
}

.who-am-i-image img {
  width: 150px; /* Adjust size as needed */
  height: 150px;
  border-radius: 50%;
  object-fit: cover;
  border: 3px solid #6450a2; /* Border to match branding */
}

.who-am-i-text {
  width: 100%;
  max-width: 950px; /* Limit the width for better readability */
  text-align: start;
}

.who-am-i h2 {
  font-size: 2em;
  margin-bottom: 10px;
  color: #333333; /* Darker color for better readability */
  text-align: center;
}

.who-am-i p {
  color: #555555; /* Neutral grey for text */
  margin-bottom: 10px;
  line-height: 1.6; /* Improved readability */
  font-size: 1em;
}

.preach {
  text-align: left;
  padding: 20px;
}

.who-am-i ul {
  list-style: none;
  padding-left: 0;
  margin-top: 10px;
}

.who-am-i ul li {
  position: relative;
  padding-left: 20px;
  margin-bottom: 10px;
  color: #555555; /* Consistent text color */
  font-size: 1.2em; /* Standardized font size */
}

.who-am-i ul li::before {
  content: '>';
  position: absolute;
  left: 0;
  top: 0;
  color: #6450a2; /* Updated to match the button color */
  font-weight: bold;
}

.who-am-i .book-call-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #6450a2; /* Consistent branding color */
  color: white;
  font-size: 1.3em;
  text-decoration: none;
  border: none;
  border-radius: 5px;
  align-self: center;
  margin-top: 30px;
  transition: background-color 0.3s; /* Smooth transition */
}

.who-am-i .book-call-button:hover {
  background-color: #4322bb; /* Darker shade on hover */
}

.Decision-Time {
  align-items: center; /* Align items to the bottom */
  padding: 3rem;
  background-color: #fdfdfd;
  border-radius: 10px;
  gap: 2rem;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 33px rgba(0, 0, 0, 0.08);
}

/* Responsive Design */
@media (max-width: 768px) {
  .who-am-i-content {
    text-align: center; /* Center text for mobile */
  }

  .preach {
    padding: 10px;
  }

  .who-am-i-text {
    margin-bottom: 20px; /* Space between text and image */
  }

  .Decision-Time {
    padding: 40px;
  }

  .who-am-i-image img {
    width: 120px; /* Smaller image on mobile */
    height: 120px;
  }
}
