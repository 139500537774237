body {
  font-family: Arial, sans-serif;
  line-height: 1.6;
  margin: 0;
  padding: 0;
  background-color: #ffffff;
  color: #333;
}

.services {
  max-width: 1200px;
  margin: 40px auto;
  padding: 0 20px;
  height: 100%;
  width: 100%;
}

.services-image-container {
  display: flex;
  margin: 0;
  height: 100%;
  width: 100%;
  border-radius: 8px 8px 0 0;
}

.services-image-container img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 8px 8px 0 0;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  margin: 0;
}

.services-content-container {
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  border-radius: 0 0 8px 8px;
  padding: 5%;
}

.services-title {
  text-align: left;
  color: #4e699b;
}

.services-intro {
  text-align: left;
  font-size: 1.2em;
  margin-bottom: 20px;
}

.services-description {
  text-align: left;
  font-size: 1.1em;
  margin-bottom: 20px;
}

.services-section {
  margin-top: 20px;
}

.services-section h2 {
  color: #4e699b;
}

.service {
  margin-bottom: 20px;
}

.service h3 {
  color: #4e699b;
  font-size: 1.1em;
  margin-bottom: 10px;
}

.services-why-choose-us {
  margin-top: 40px;
}

.services-why-choose-us h2 {
  color: #4e699b;
}

.services-why-choose-us ul {
  list-style: none;
  padding: 0;
}

.services-why-choose-us ul li {
  margin-bottom: 10px;
  font-size: 1.1em;
}

.services-cta {
  text-align: center;
  margin-top: 40px;
}

.services-cta h2 {
  color: #4e699b;
  margin-bottom: 10px;
}

.services-cta p {
  font-size: 1.1em;
  margin-bottom: 20px;
}

.services-btn-learn-more {
  display: inline-block;
  padding: 10px 20px;
  background-color: #4e699b;
  color: #ffffff;
  text-decoration: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.services-btn-learn-more:hover {
  background-color: #003399;
}
