/* Blog.css */

.blog-container {
  font-family: 'Arial, sans-serif';
  text-align: center;
  padding: 20px;
  background-color: #ffffffd7;
}

h1 {
  font-size: 2em;
  color: #333;
  margin-bottom: 20px;
}

.filter-buttons {
  margin-bottom: 20px;
}

.filter-button {
  background-color: #ddd;
  border: none;
  padding: 10px 20px;
  margin: 0 5px;
  cursor: pointer;
  font-size: 1em;
  transition: background-color 0.3s;
}

.filter-button.active,
.filter-button:hover {
  background-color: #bbb;
}

.blog-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 20px;
}

.blog-card {
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  text-align: left;
  transition: transform 0.3s;
}

.blog-card a {
  text-decoration: none;
  color: inherit;
}

.blog-card img {
  width: 100%;
  height: auto;
}

.blog-card h3 {
  font-size: 1.2em;
  margin: 10px;
}

.blog-card p {
  margin: 0 10px 10px;
  font-size: 0.9em;
  color: #666;
}

.tag {
  display: inline-block;
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  margin: 10px;
  border-radius: 20px;
  font-size: 0.8em;
}

.blog-card:hover {
  transform: scale(1.05);
}
