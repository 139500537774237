.navbar {
  display: flex;
  position:sticky;
  justify-content: space-between;
  align-items: center;
  background-color: #ffffff;
  height: 100%;
  padding: 1rem 2rem 0 2rem;
  width: 100%;
  z-index: 35;
  margin-bottom: 0px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1), 0 1px 33px rgba(0, 0, 0, 0.08);
  
}

.navbar-container {   
  width: 100%;
  padding: 1rem 2rem;
  align-content: center;
  height: 100%;
  z-index: 35;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.logo {
  display: flex;
  align-items: center;
}

.tt {
  height: auto;
  max-width: 200px; /* Adjust as needed */
  width: 100%;
  margin-left: 25%;
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 2rem;
}

.nav-links li a {
  font-style: normal;
  font-family: "Open Sans", sans-serif;
  letter-spacing: .5cap;
  color: #6a4fc3;
  text-decoration: none;
  font-size: 1rem;
}

.nav-links li a:hover {
  color: #3054e4;
}

.nav-toggle {
  display: none;
  font-size: 2rem;
  cursor: pointer;
}

.start-button {
  display: none;
  background-color: #ffffff;
  color: #6a4fc3;
  border: 1px solid #6a4fc3;
  padding: 0.5rem 1rem;
  cursor: pointer;
  font-size: 1rem;
  border-radius: 4px;
}

.start-button:hover {
  background-color: #2538b8;
  color: #ffffff;
}

@media (max-width: 800px) {
  .nav-links {
    display: none;
    flex-direction: column;
    width: 100%;
  }

  .logo-text {
    display: none;
  }

  .start-button {
    display: flex;
  }

  .start-button.open {
    display: none;
  }

  .logo {
    justify-content: center;
    text-align: center;
    font-size: medium;
    margin-left: 12%;
    margin-top: 0px;
  }

  .logo.open {
    display: none;
  }

  .nav-links.open {
    display: flex;
    justify-content: center;
    text-align: center;
    margin-right: 2rem; /* Adjust margin to account for nav-toggle displacement */
    padding: 0;
  }

  .nav-toggle {
    display: flex;
    height: 100%;
  }
.tt{margin-left:0px}
  .navbar {
    flex-direction: row;
    margin-bottom: 0px;
    padding-bottom: 8px;
  }

  .navbar-container {
    padding: 0;
    margin: 0;
    display: flex;
  }

  .navbar-container.open {
    padding: 0;
    margin: 0;
    display: flex;
    align-items: flex-start;
  }

  .nav-links li {
    margin: 10px 0;
  }
}
