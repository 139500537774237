.how-is-this-different {
  align-items: center;
  text-align: center;
  padding: 6rem 3rem;
  background-color: rgb(255, 255, 255);
}

.how-is-this-different h2 {
  font-size: 2rem;
  margin-bottom: 4rem;
  transform: translateY(20px);
}

.features {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
}

.feature {
  flex: 1;
  margin: 1rem;
  padding: 1rem;
  border-radius: 10px;
  text-align: center;
  opacity: 0;
  transform: translateY(20px);
  transition: opacity 1s ease-out, transform 1s ease-out;
}

.icon {
  color: #63599a;
  margin-bottom: 1rem;
}

.feature p {
  font-size: 1rem;
  color: #555;
}

.fade-in {
  opacity: 1;
  transform: translateY(0);
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .feature {
    flex: 1 1 100%;
    margin: 1rem 0;
  }

  .how-is-this-different h2 {
    font-size: 1.5rem;
  }

  .feature p {
    font-size: 0.9rem;
  }
}
